<script>
  import {
    CountTo
  } from "vue3-count-to";
  import serverConfig from '@/helpers/config';
  import { mapActions } from "vuex";
  import Swal from "sweetalert2";
  import axios from 'axios';

export default {
    components: {
        CountTo,
    },
    name:'SummaryInspections',
    setup() {
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk };
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        data:{		
          total:0,
          processing:0,
          pending:0,
          completed:0,
          problem:0,
          sent:0,
        }
      }
    },
    mounted(){
      this.getList()
    },
    methods:{
      ...mapActions('layout', ['changeLayoutType', ]),
    
      axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
              Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
              this.$router.push('/')                   
              return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
     
    getList() {
      let year = new Date().getFullYear()
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/summary/inspections/${year.toString()}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data = response.data
          }        
        })
    },
   
    }

};
</script>

<template>
      <div class="row">
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Totale anno</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="data.total">
                  <count-to :startVal="0" :endVal="data.total" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info-2 rounded-circle fs-4">
                    <i class="ri-ticket-2-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Sopralluoghi in lavorazione</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="data.processing">
                  <count-to :startVal="0" :endVal="data.processing" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Sopralluoghi in attesa</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="data.pending">
                  <count-to :startVal="0" :endVal="data.pending" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="bx bx-time-five "></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Sopralluoghi con problemi</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="data.pending">
                  <count-to :startVal="0" :endVal="data.problem" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-danger rounded-circle fs-4">
                    <i class="bx bx-alarm-exclamation"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Sopralluoghi completi</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="data.completed">
                  <count-to :startVal="0" :endVal="data.completed" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
             
              <div>
                <p class="fw-medium text-muted mb-0"></p>
                <h2 class="mt-4 ff-secondary fw-semibold" >
                  
                </h2>

              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
